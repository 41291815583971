<script setup lang="ts">
const props = defineProps({
  width: {
    type: String as PropType<StandardComponentSizeExtended>,
    default: 's',
    validator(value: StandardComponentSizeExtended) {
      return ['xl', 'l', 'm', 's', 'xs'].includes(value)
    },
  },
})
</script>

<template>
  <div class="centered-content" :class="`centered-content--width-${props.width}`">
    <slot />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
@max-w-xs: 640px;
@max-w-s: 880px;
@max-w-m: 1040px;
@max-w-l: 1200px;
@max-w-xl: 1600px;

.centered-content {
  margin-left: auto;
  margin-right: auto;

  &--width-xs {
    max-width: @max-w-xs;
  }
  &--width-s {
    max-width: @max-w-s;
  }
  &--width-m {
    max-width: @max-w-m;
  }
  &--width-l {
    max-width: @max-w-l;
  }
  &--width-xl {
    max-width: @max-w-xl;
  }
}
</style>
